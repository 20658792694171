import { Pipe, PipeTransform } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service';

@Pipe({
  name: 'customTranslate',
  pure: false,
  standalone: true,
})
export class CustomTranslatePipe implements PipeTransform {
  constructor(private customTranslateService: CustomTranslateService) {}

  transform(value: string, ...args: unknown[]): string {
    return this.customTranslateService.translate(value);
  }
}
