import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateService {
  static readonly danish = 'da';
  static readonly english = 'en';

  private languageCodeSubject = new BehaviorSubject<string>(
    CustomTranslateService.danish
  );
  languageCode$ = this.languageCodeSubject.asObservable();

  constructor() {}

  getLanguageCode(): string {
    return this.languageCodeSubject.value;
  }

  toggleLanguageCode(): void {
    const languageCode = this.getAlternativeLanguageCode(
      this.languageCodeSubject.value
    );
    this.languageCodeSubject.next(languageCode);
  }

  setLanguageCode(languageCode: string): void {
    this.languageCodeSubject.next(languageCode);
  }

  getAlternativeLanguageCode(value: string): string {
    return value === CustomTranslateService.danish
      ? CustomTranslateService.english
      : CustomTranslateService.danish;
  }

  translate(value: string): string {
    if (this.languageCodeSubject.value === CustomTranslateService.english) {
      return this._translateToEnglish(value);
    }

    return value;
  }

  private _translateToEnglish(value: string): string {
    const translations: { [key: string]: string } = {
      Brugernavn: 'Username',
      Firmanummer: 'Firm number',
      Adgangskode: 'Password',
      Eller: 'Or',
      Gensend: 'Resend',
      Ret: 'Edit',

      'WORXS | Log ind': 'WORXS | Log in',

      'Log ind': 'Log in',
      'Glemt adgangskode?': 'Forgot password?',
      'Fortsæt med Microsoft 365': 'Continue with Microsoft 365',
      'Husk mig': 'Remember me',
      'Indtast ny adgangskode': 'Enter new password',
      'Bekræft adgangskode': 'Confirm password',
      'De to adgangskoder er ikke ens.': "The two passwords don't match.",
      'Token udløbet. Prøv venligst igen.': 'Token expired. Please try again.',
      'Glemt adgangskode': 'Forgot password',
      'Tjekker gyldighed, vent venligst...':
        'Checking validity, please wait...',
      'Opdaterer adgangskode...': 'Updating password...',

      'Logger ind, vent venligst': 'Logging in, please wait',
      'Afsender mail, vent venligst': 'Sending mail, please wait',

      'Der findes ikke nogen brugere med disse loginoplysninger, har du tastet korrekt?':
        'There are no users with these login details, have you typed correctly?',

      'Din adgangskode er nulstillet, du nu kan logge ind med din nye kode.':
        'Your password has been reset, you can now log in with your new password.',

      'En mail er sendt til mailadressen tilknyttet den indtastede bruger.':
        'An email has been sent to the email address associated with the entered user.',

      'Beklager, vi kan ikke finde nogen WORXS bruger tilknyttet den Microsoft 365 konto.':
        'Sorry, we cannot find any WORXS user associated with the Microsoft 365 account.',
    };

    if (value in translations) {
      return translations[value];
    }

    return value;
  }
}
